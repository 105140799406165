import axios from '@axios'
import qs from 'qs'

const prefixPath = '/api/system/company'
export default {

  getCompanyByDomain(domain) {
    return axios.get(`${prefixPath}/getCompanyByDomain`, {
      params: {
        domain,
      },
    })
  },

}
