<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3"
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <!-- login form -->
                <v-card-text>
                  <v-row class="mb-3">
                    <v-col
                      style="text-align: center"
                      md="12"
                    >
                      <span

                        class="text-h5"
                      >
                        {{ getAppName }}网上订货商城管理后台
                      </span>
                    </v-col>
                  </v-row>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      ref="phone"
                      v-model="phone"
                      outlined
                      label="手机号码"
                      placeholder="手机号码"
                      :error-messages="errorMessages.phone"
                      :rules="rules.required"
                      hide-details="auto"
                      class="mb-6"
                      autocomplete="username"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="密码"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      :rules="rules.required"
                      hide-details="auto"
                      class="mb-2"
                      autocomplete="password"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <!--                    <div class="d-flex align-center justify-space-between flex-wrap">-->
                    <!--                      <v-checkbox-->
                    <!--                        hide-details-->
                    <!--                        label="Remember Me"-->
                    <!--                        class="mt-0"-->
                    <!--                      >-->
                    <!--                      </v-checkbox>-->
                    <!--                    </div>-->

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      登录
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import themeConfig from '@themeConfig'
import { useRouter } from '@core/utils'
import { getCurrentInstance } from '@vue/composition-api'
import AuthApi from '@/api/auth'
import CompanyApi from '@/api/system/company'

export default {
  data() {
    return {
      phone: '',
      password: '',
      rules: {
        required: [
          v => !!v || '内容不能为空',
        ],
      },
      companyId: '',
    }
  },
  computed: {
    getAppName() {
      let appName = ''
      if (JSON.parse(localStorage.getItem('companyInfo'))) {
        appName = JSON.parse(localStorage.getItem('companyInfo')).name
      }

      return appName
    },
  },
  mounted() {
    this.$refs.phone.focus()
    localStorage.removeItem('companyInfo')

    const domain = location.host.split('.')[0]
    CompanyApi.getCompanyByDomain(domain).then(res => {
      const { data } = res.data
      this.companyId = res.data.data.id

      localStorage.setItem('companyInfo', JSON.stringify(data))
    })
  },
  methods: {
    async handleFormSubmit() {
      const isFormValid = this.$refs.loginForm.validate()
      if (!isFormValid) return
      let userName = ''
      await AuthApi.login(this.phone, this.password, this.companyId, 'password')
        .then(response => {
          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          const { data } = response.data
          const { auth } = data

          if (auth) {
            localStorage.setItem('userInfo', JSON.stringify(auth))
            userName = auth.organization
            AuthApi.me().then(res => {
              const { id, roles, permissionDtos } = res.data.data

              const userAbility = this.convertAbility(permissionDtos)
              this.vm.$ability.update(userAbility)
              localStorage.setItem('userAbility', JSON.stringify(userAbility))

              const user = {
                id,
                fullName: userName,
                avatar: require('@/assets/images/avatars/1.png'),
                role: roles.join(','),
              }

              localStorage.setItem('userData', JSON.stringify(user))
              this.router.push('/')
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    convertAbility(permissionDtos) {
      const { menuList, resource } = permissionDtos
      const ability = []
      if (menuList) {
        menuList.forEach(menu => {
          if (menu.parentId === -1) {
            return
          }
          const obj = {
            subject: menu.code,
            action: 'read',
          }
          ability.push(obj)
        })
      }
      if (resource) {
        resource.forEach(res => {
          const subject = res.code
          res.resourceList.forEach(node => {
            const obj = {
              subject,
              action: node.code,
            }
            ability.push(obj)
          })
        })
        const basePermission = {
          subject: 'Public',
          action: 'read',
        }

        ability.push(basePermission)
      }

      return ability
    },
  },
  setup() {
    const { router } = useRouter()
    const vm = getCurrentInstance().proxy

    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      errorMessages: [],
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      isPasswordVisible: false,
      router,
      vm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/pages/auth.scss';
</style>
